import CopyButton from '../../shared/CopyButton'

export default class TargetCopyButton extends React.PureComponent
  
  constructor: (props) ->
    super(props)

    # Reference to the child Copy Button, so that we can forward a call to its 'showSuccessPopover' method
    @copyButtondRef = React.createRef();

    @copyTargetToClipboard = @copyTargetToClipboard.bind(this)

  componentDidMount: ->
    window.TargetCopyButton = @ # to be able to call `window.TargetCopyButton.showSuccessPopover()` from outside (Ctrl-C shortcut)

  copyTargetToClipboard: ->
    navigator.clipboard.writeText(@props.targetText)

  showSuccessPopover: ->
    @copyButtondRef.current.showSuccessPopover() if @copyButtondRef.current

  render: ->
    <CopyButton ref={@copyButtondRef}
                className="target-copy"
                initialText={@props.i18n.initialText}
                successText={@props.i18n.successText}
                buttonVisible={@props.targetText.length}
                onClick={@copyTargetToClipboard} />
