import CopyButton from '../shared/CopyButton'

export default class TableRow extends React.PureComponent
  constructor: (props) ->
    super(props)

    @copyButtonRef = React.createRef()

    @onClickCensoredKey = @onClickCensoredKey.bind(this)
    @copyKeyToClipboard = @copyKeyToClipboard.bind(this)

  onClickCensoredKey: ->
    @copyKeyToClipboard()
    @copyButtonRef.current.showSuccessPopover() if @copyButtonRef.current

  copyKeyToClipboard: ->
    navigator.clipboard.writeText(@props.apiKey.key)

  render: ->
    if @props.apiKey.isActive
      classes = "active-key"
    else
      classes =  "revoked-key"
      classes += " hidden" if !@props.rowVisible

    <tr className={classes} key={@props.apiKey.id}>
      { @renderNameField()            }
      { @renderKeyField()             }
      { @renderStatusField()          }
      { @renderLastUseField()         }
      { @renderCreationDateField()    }
      { @renderActionsDropdownField() }
    </tr>

  renderNameField: ->
    <td className="name-col">
      <span title={@props.apiKey.name}>
        {@props.apiKey.name}
      </span>

    </td>

  renderKeyField: ->
    censoredKey = @props.apiKey.key.replace(/^(.{4}).+(.{4})$/, '$1******$2')

    <td className="key-col">
      <span className="censored-key"
            onClick={@onClickCensoredKey}
            title={@props.i18n.censoredKey.title}>
        {censoredKey}
      </span>
      <CopyButton ref={@copyButtonRef}
                  className="api-key-copy"
                  initialText={@props.i18n.copyButton.initialText}
                  successText={@props.i18n.copyButton.successText}
                  buttonVisible={true}
                  onClick={@copyKeyToClipboard} />
    </td>

  renderStatusField: ->
    if @props.apiKey.isActive
      classes = 'badge status-active'
      label   = @props.i18n.status.active
    else
      classes = 'badge status-revoked'
      label   = @props.i18n.status.revoked

    <td className="status-col">
      <span className={classes}>
        {label}
      </span>
    </td>

  renderLastUseField: ->
    attributes = { title: @props.apiKey.fullFormattedLastUseTime }

    <td className="last-use-col">
      <span {...attributes}>
        {@props.apiKey.formattedLastUseDate}
      </span>
    </td>

  renderCreationDateField: ->
    <td className="creation-date-col">
      <span title={@props.apiKey.fullFormattedCreationTime}>
        {@props.apiKey.formattedCreationDate}
      </span>
    </td>

  renderActionsDropdownField: (key) ->
    <td className="actions-col">
      <div className="dropdown">
        <button type="button"
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-label={@props.i18n.dropdown.ariaLabel}
                aria-expanded="false">
          <i className="fas fa-ellipsis" aria-hidden="true"></i>
        </button>

        <div className="dropdown-menu">
          { @renderRenameButton()    }
          { @renderDropdownDivider() }
          { @renderRevokeButton()    }
        </div>
      </div>
    </td>

  renderRenameButton: ->
    <button className="dropdown-item danger"
            onClick={@props.editKey.bind(this, @props.apiKey.id, @props.apiKey.name)}>
      {@props.i18n.dropdown.rename}
    </button>


  renderDropdownDivider: ->
    if @props.apiKey.isActive
      <hr className="dropdown-divider" />

  renderRevokeButton: ->
    if @props.apiKey.isActive
      <button className="dropdown-item text-danger"
              onClick={@props.revokeKey.bind(this, @props.apiKey.id)}>
        {@props.i18n.dropdown.revoke}
      </button>

