import Modal    from './api_keys/Modal'
import TableRow from './api_keys/TableRow'

export default class ApiKeys extends React.PureComponent
  MODAL_ID = 'modal-neuro-api-key-name-edit'

  constructor: (props) ->
    super(props)

    @state =
      apiKeys:            @props.apiKeys
      revokedKeysVisible: false
      sortColumn:         'date'
      sortOrder:          'DESC'
      modalAction:        'create' # create|update
      editedId:           0
      editedName:         ''

    @newKey           = @newKey.bind(this)
    @createKey        = @createKey.bind(this)
    @editKey          = @editKey.bind(this)
    @updateEditedName = @updateEditedName.bind(this)
    @updateKey        = @updateKey.bind(this)
    @revokeKey        = @revokeKey.bind(this)
    @showRevokedKeys  = @showRevokedKeys.bind(this)
    @hideRevokedKeys  = @hideRevokedKeys.bind(this)
    @hideModal        = @hideModal.bind(this)

  reloadKeys: ->
    http.get("/#{@props.currentLocale}/account/api_keys.json", {}, (data) =>
      @setState(apiKeys: data)
    )

  newKey: ->
    @setState({
      modalAction: 'create'
      editedId:    0
      editedName:  @props.i18n.modal.nameField.placeholder
    }, @showModal)

  createKey: ->
    params =
      apiKey:
        name: @state.editedName

    http.post("/#{@props.currentLocale}/account/api_keys.json", params, (data) =>
      if data.success
        @hideModal()
        @reloadKeys()
    )

  editKey: (id, name) ->
    @setState({
      modalAction: 'update'
      editedId:    id
      editedName:  name
    }, @showModal)

  updateEditedName: (e) ->
    @setState(editedName: e.target.value)

  updateKey: (name) ->
    params =
      apiKey:
        name: @state.editedName

    http.put("/#{@props.currentLocale}/account/api_keys/#{@state.editedId}.json", params, (data) =>
      if data.success
        @hideModal()
        @reloadKeys()
    )

  revokeKey: (id, e) ->
    if window.confirm(@props.i18n.revokeConfirmation)
      http.delete("/#{@props.currentLocale}/account/api_keys/#{id}.json", {}, (data) =>
        if data.success
          @reloadKeys()
      )

  showRevokedKeys: ->
    @setState(revokedKeysVisible: true)

  hideRevokedKeys: ->
    @setState(revokedKeysVisible: false)

  showModal: ->
    modal = Bootstrap.Modal.getOrCreateInstance("##{MODAL_ID}")
    modal.show()

  hideModal: ->
    modal = Bootstrap.Modal.getOrCreateInstance("##{MODAL_ID}")
    modal.hide()

  someRevokedKeys: ->
    @state.apiKeys.some((key) => !key.isActive)

  someActiveKeys: ->
    @state.apiKeys.some((key) => key.isActive)

  sortKeys: (keys) ->
    if @state.sortColumn == 'date'
      keys = _.sortBy(keys, 'date')
    else if @state.sortColumn == 'name'
      keys = _.sortBy(keys, (a) => a.name.toLowerCase())

    if @state.sortOrder == 'DESC'
      _.reverse(keys)
    else
      keys

  render: ->
    <div className="card d-block" id="api-keys-manager">
      <button className="btn btn-primary float-end"
              onClick={@newKey}>
        {@props.i18n.createButton}
      </button>

      <h1>
        {@props.i18n.cardTitle}
      </h1>

      <div className="description">
        <p dangerouslySetInnerHTML={{ __html: @props.i18n.description }}>
        </p>
        <p>
          <a href={"#{@props.paths.docsApiPath}#authentication"}>
            {@props.i18n.docsApiLinkLabel}
          </a>
        </p>
      </div>

      { @renderTable() }
      { @renderRevokedKeysToggler() }
      { @renderModal() }
    </div>

  renderTable: ->
    # Wrapping the table in a table-responsive div so we can force horizontal scroll on mobile
    <div className="table-responsive">
      <table className="table table-borderless">
        <thead>
          <tr>
            <th className="name-col">
              {@props.i18n.tableColumns.name}
            </th>
            <th className="key-col">
              {@props.i18n.tableColumns.apiKey}
            </th>
            <th className="status-col">
              {@props.i18n.tableColumns.status}
            </th>
            <th className="last-use-col">
              {@props.i18n.tableColumns.lastUse}
            </th>
            <th className="creation-date-col">
              {@props.i18n.tableColumns.creationDate}
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          { @renderBlankRow() }
          { @renderRows() }
        </tbody>
      </table>
    </div>

  renderBlankRow: ->
    if !@someActiveKeys() && !@state.revokedKeysVisible
      <tr className="blankslate">
        <td colSpan="6" className="text-center">
          {@props.i18n.noActiveKeys}
        </td>
      </tr>

  renderRows: ->
    sortedKeys = @sortKeys(@state.apiKeys)

    sortedKeys.map((apiKey) =>
      <TableRow key={apiKey.id}
                apiKey={apiKey}
                rowVisible={@state.revokedKeysVisible}
                i18n={@props.i18n.tableRow}
                editKey={@editKey}
                revokeKey={@revokeKey} />
    )

  renderRevokedKeysToggler: ->
    classes = 'btn btn-link revoked-keys-toggler'

    if @someRevokedKeys()
      if @state.revokedKeysVisible
        <button className={classes}
                onClick={@hideRevokedKeys}>
          <i className="far fa-eye-slash"></i>
          {@props.i18n.revokedKeysToggler.hide}
        </button>
      else
        <button className={classes}
                onClick={@showRevokedKeys}>
          <i className="far fa-eye"></i>
          {@props.i18n.revokedKeysToggler.show}
        </button>

  renderModal: ->
    <Modal modalId={MODAL_ID}
           environment={@props.environment}
           modalAction={@state.modalAction}
           editedName={@state.editedName}
           i18n={@props.i18n.modal}
           updateEditedName={@updateEditedName}
           createKey={@createKey}
           updateKey={@updateKey}
           hideModal={@hideModal} />
