export default class LimitWarning extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    # We receive snake-cased codes ('ip_monthly'), but we need them camelized ('ipMonthly') for localization
    warningCode = humps.camelize(@props.warningCode)
    userType    = if @props.currentUser then 'user' else 'visitor'

    text = @props.i18n[userType][warningCode].text
    text = text.replace('%{max_number}', @props.maxSourceSize) if @props.warningCode == 'char'
    cta  = @props.i18n[userType][warningCode].cta

    <div className="alert" role="alert">
      <span>
        {text}
      </span>

      <hr />

      <p className="sign-up-cta"
         dangerouslySetInnerHTML={{__html: cta}} />

      { @renderButtons() }
    </div>

  renderButtons: ->
    if @props.currentUser
      <button className="btn btn-secondary btn-sm"
              onClick={@props.openModal.bind(this, 'limit')}>
        {@props.i18n.iNeedMore}
      </button>
    else
      <div className="sign-up-buttons">
        <div className="oauth-buttons">
          <a href={@props.paths.googleSignUpPath} data-method="post" rel="nofollow" className="btn btn-google-outline">
            <img src={@props.assets.googleOauthIcon} />
            {@props.i18n.signUpWithGoogle}
          </a>
        </div>
        <span>
          {@props.i18n.or}
          {' '}
          <a href={@props.paths.signUpPath}>
            {@props.i18n.signUpWithEmail}
          </a>
        </span>
      </div>
