export default class Modal extends React.PureComponent
  constructor: (props) ->
    super(props)

    @submitName           = @submitName.bind(this)
    @triggerInputKeyDown  = @triggerInputKeyDown.bind(this)

  componentDidMount: ->
    modal = document.getElementById(@props.modalId)
    modal.addEventListener('shown.bs.modal', =>
      $('input#api-key-name').trigger('focus')
    )
    # Prevent accessibility error in the console (focus is still inside the modal after it closed)
    # See: https://github.com/twbs/bootstrap/issues/41005#issuecomment-2497670835
    modal.addEventListener('hide.bs.modal', =>
      focusedElement = document.activeElement
      focusedElement.blur() if modal.contains(focusedElement)
    )

  submitName: (e) ->
    if @props.modalAction == 'create'
      @props.createKey()
    else
      @props.updateKey()

  triggerInputKeyDown: (e) ->
    @submitName() if e.code == 'Enter' && @nameIsValid()

  nameIsValid: ->
    @props.editedName.trim() != ''

  render: ->
    classes =  'modal'
    classes += ' fade' if @props.environment != 'test'

    <div className={classes} id={@props.modalId} tabIndex="-1" aria-labelledby={"#{@props.modalId}-label"} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          { @renderHeader() }
          { @renderBody()   }
          { @renderFooter() }
        </div>
      </div>
    </div>

  renderHeader: ->
    <div className="modal-header">
      <h4 id={"#{@props.modalId}-label"}
          className="modal-title">
        {@props.i18n.title[@props.modalAction]}
      </h4>
      <button type="button"
              className="btn-close"
              aria-label={@props.i18n.closeButtonLabel}
              onClick={@props.hideModal} />
    </div>

  renderBody: ->
    <div className="modal-body">
      <label htmlFor="api-key-name" className="form-label">
        {@props.i18n.nameField.label}
      </label>
      <input id="api-key-name"
             className="form-control"
             type="text"
             placeholder={@props.i18n.nameField.placeholder}
             value={@props.editedName}
             onChange={@props.updateEditedName}
             onKeyDown={@triggerInputKeyDown} />
    </div>

  renderFooter: ->
    <div className="modal-footer">
      <button type="submit"
              className="btn btn-primary"
              onClick={@submitName}
              disabled={!@nameIsValid()}>
        {@props.i18n.actionButtons[@props.modalAction]}
      </button>
      <button className="btn btn-cancel"
              onClick={@props.hideModal}>
        {@props.i18n.actionButtons.cancel}
      </button>
    </div>
